$(document).ready(function() {

	$('body').on('submit', '.js-validate-form', function(e) {

		if (e.isDefaultPrevented()) {
			// handle the invalid form...
		} else {
			$('.newslatter-btn').prop('disabled', true);

			$.ajax({
				type: "POST",
				url: $('.js-validate-form').attr('action'),
				data: $('.js-validate-form').serialize(),
				dataType: 'json',
				success: function(data) {

					if (data.status == "success") {

						// $('.js-validate-form .msg-success').removeClass('hidden');
						$('.js-validate-form input:not(.js-validate-form--send)').val("");
						$('.newslatter-btn').prop('disabled', false);
						$('.js-validate-form').append('<div style="color: white; text-align: center; font-size: 20px; font-weight: 600;" class="js-validate-form-text-after-sending-form">' + data.text_end + '</div>');

						setTimeout(function() {
							// $('.js-validate-form .msg-success').addClass('hidden');
							$('.js-validate-form-text-after-sending-form').remove();
						}, 3000);
					} else {

						$("#newslatter-input-email-id-un").next('.with-errors').text(data.errors[5011]);

					}
				}
			});
		}
		return false;
	});
 
});